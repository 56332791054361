import ModalCustom from "../../../Modal/ModalCustom";
import successLight from "../../../../assets/images/success-light.svg";
import successDark from "../../../../assets/images/success-dark.svg";
import "./genAiModal&Button.css";
import { useContext, useEffect, useRef, useState } from "react";
import StoreContext from "../../../../store/storecontext";
import { ThemeContext } from "../../../../ThemeContext.jsx";
import starone from "../../../../assets/images/startone.svg";
import startwo from "../../../../assets/images/startwo.svg";
import starthree from "../../../../assets/images/starthree.svg";
import "./twinkle.css";

const GenAiModal = (props) => {
  const store = useContext(StoreContext);
  const [requestLoading, setRequestLoading] = useState(false);
  const [savedDescription, setSavedDescription] = useState(false);
  const [theme] = useContext(ThemeContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [dotAnimation, setDotAnimation] = useState("");
  const counter = useRef(0);

  useEffect(() => {
    const animate = setInterval(() => {
      if (counter.current < 4) {
        setDotAnimation((prev) => prev + ".");
        counter.current++;
      }
      if (counter.current >= 4) {
        setDotAnimation("");
        counter.current = 0;
      }
    }, 500);

    return () => clearInterval(animate);
  }, []);

  useEffect(() => {
    if (savedDescription) {
      setTimeout(() => {
        props.closeModal();
      }, 3000);
    }
  }, [savedDescription]);

  const generateDescription = (e) => {
    e?.preventDefault();
    if (e.code === "Enter" || (e.type === "mousedown" && e.button === 0)) {
      const controller = new AbortController();
      setAbortController(controller);

      setIsDataLoading(true);
      const bbDescriptionLimit = process.env.REACT_APP_BB_DESCRIPTION_LIMIT;
      setRequestLoading(true);
      store.services.genIAService
        .GetGenIADescription(
          bbDescriptionLimit,
          props.lastGeneratedDescription,
          props.context,
          controller.signal
        )
        .then((response) => {
          props.setLastGeneratedDescription(response.data.result);
        })
        .catch((e) => {
          if (e.name === "AbortError") {
            console.log("Request was canceled:", e);
          } else {
            console.log("Error generating description:", e);
          }
        })
        .finally(() => {
          setIsDataLoading(false);
          setRequestLoading(false);
        });
    }
  };

  const handleAccept = () => {
    const isValid = props.BB_Validations.validateDescription(
      props.lastGeneratedDescription
    );
    props.replaceDescription(
      "description",
      props.lastGeneratedDescription,
      isValid.isValid
    );
    props.updateDescUI();
    setSavedDescription(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (abortController) {
      abortController.abort();
      setIsDataLoading(false);
      setRequestLoading(false);
      console.log("Request canceled by user.");
    }
  };

  const modalBody = (
    <>
      <h2 id="description-title" aria-live="polite" aria-label="Description">
        <b>Description</b>
      </h2>
      <p
        id="generated-description"
        data-testid="gen-description"
        aria-live="polite"
        aria-label={props.lastGeneratedDescription}
      >
        {props.lastGeneratedDescription}
      </p>
      <p
        className="disclaimer"
        id="ai-disclaimer"
        aria-live="polite"
        aria-label="Response generated by AI. Verify accuracy and privacy restrictions before use"
      >
        Response generated by AI. Verify accuracy and privacy restrictions
        before use.
      </p>
    </>
  );

  const successBody = (
    <div className="success-body" style={{ marginBottom: 10 }}>
      <img
        src={theme === "dark" ? successDark : successLight}
        className="success-icon"
        alt="Check mark"
        id="success-icon"
        aria-labelledby="success-title"
      />
      <p
        style={{ fontWeight: "bold", fontSize: 28, marginBottom: 5 }}
        id="success-title"
      >
        Data generated successfully!
      </p>
      <p id="success-message" aria-live="polite">
        Your Gen AI data is ready. Explore and analyze the results.
      </p>
      <button
        id="success-ok-button"
        className="modal-button-second"
        style={{ backgroundColor: "var(--light-mode-secondary-button-color)" }}
        onClick={props.closeModal}
        aria-label="Close the modal and view results"
      >
        OK
      </button>
    </div>
  );

  const TwinkleImage = (src, top, left, delay) => {
    return (
      <img
        src={src}
        style={{
          position: "absolute",
          top: top,
          left: left,
          width: "36px",
          height: "34px",
          visibility: "visible",
          animationDelay: delay
        }}
        className="twinkle"
        alt="Twinkling star"
      />
    );
  };

  const generatingBody = (
    <div
      style={{
        padding: "25px 30px",
        borderRadius: "4px 16px 16px 16px",
        display: "grid"
      }}
    >
      <div
        className="starwrapper"
        style={{
          position: "relative",
          marginLeft: "42%",
          width: 50,
          height: 50
        }}
      >
        {TwinkleImage(startwo, "-14px", "13px", "0s")}
        {TwinkleImage(starone, "7px", "-2px", "0.5s")}
        {TwinkleImage(starthree, "18px", "21px", "1s")}
      </div>
      <div
        style={{
          marginTop: 25,
          width: "-webkit-fill-available",
          marginBottom: 15
        }}
      >
        <p
          className={theme + "-gradient"}
          id="generating-description"
          style={{
            fontWeight: 600,
            fontSize: "18px",
            marginLeft: "25%",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text"
          }}
          aria-live="polite"
        >
          Generating Description{dotAnimation}
        </p>
      </div>
      <button
        id="cancel-button"
        className={`modal-button-second ${theme === "dark" && "pink"}`}
        style={{ marginLeft: "38%" }}
        onClick={handleCancel}
        aria-label="Cancel the generation process"
      >
        Cancel
      </button>
    </div>
  );

  return (
    <>
      {isDataLoading ? (
        <ModalCustom
          modal_title="Gen AI on Search Tools"
          modal_msg={generatingBody}
          modalSize={"description-modal generating"}
          noFooter={true}
        />
      ) : (
        <ModalCustom
          xClose={true}
          onClose={props.closeModal}
          modal_msg={savedDescription ? successBody : modalBody}
          modal_title="Gen AI on Search Tools"
          firstOptText="Regenerate"
          secondOption={true}
          secondOptText="Accept"
          modalSize={"description-modal " + (savedDescription ? "success" : "")}
          firstBtnAction={generateDescription}
          firstBtnDisabled={requestLoading}
          onClickSecondOpt={handleAccept}
          noFooter={savedDescription}
          zindex={11}
          firstOpAriaLabel="Regenerate description"
        />
      )}
    </>
  );
};

export default GenAiModal;
