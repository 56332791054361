
// this is a handler for the gen ai description feature

const showRocketBtn = (_showImg)=>{
  try {
    let genAiRocketBtn = document.querySelector(".gen-ai-rocket-button-selector");
    genAiRocketBtn.style.visibility = _showImg ? "visible" : "hidden";

    let divHolder = document.querySelector(".kw-div-elements-holder-selector");
    _showImg ? divHolder.classList.add("show-rocket-button-position") : divHolder.classList.add("show-rocket-button-position");
  } catch (error) {
    console.log("Element is hidden from the dom!");
  }
}

const enableRocketBtn = (_enableBtn)=>{
  try {
    let genAiRocketBtn = document.querySelector(".gen-ai-rocket-button-selector");
    genAiRocketBtn.disabled = !_enableBtn;
  } catch (error) {
    console.log("Element is hidden from the dom!");
  }

}

const addProps = (dirtyContext, props) => {
    let cleanContextSet = new Set();
    props.forEach((propName) => {
      if(dirtyContext.hasOwnProperty(propName) &&
         dirtyContext[propName] !== null &&
         Array.isArray(dirtyContext[propName])) {
          dirtyContext[propName].forEach((prop) => cleanContextSet.add(prop));
      } else if (!Array.isArray(dirtyContext[propName]) && 
                    dirtyContext[propName]){
        cleanContextSet.add(dirtyContext[propName]);
      }
    });

    return cleanContextSet;
}

const cleanData=(dirtyContext)=> {
    const props = ["cleandescriptionsearch","cleandescription","cleansemantic","cleandymsearch"]
    let cleanContext = addProps(dirtyContext,props);
    let contextString = Array.from(cleanContext).join(". ");
    return contextString;
}

const GetGenAiDescription = async (_url, _isValidUrl, _urlCheckerService, _genIAService, storeSiteData)=>{
  if(!_isValidUrl) return;
    let url = _url;
    let genAiDescObj = {
        description : "",
        showRocketBtn : true,
        enableRocketBtn : false
    }
    const bbDescriptionLimit = process.env.REACT_APP_BB_DESCRIPTION_LIMIT;
    if (_urlCheckerService.urlRegex.test(url)) {
      url = url.replace(/\/+$/, '');
      let res = await _genIAService.GetCrawledData(url);
      const exactmatch = res.data.hits.hits.filter((hit) => hit._source.cleanurl === url);
      const isValid = exactmatch.length === 1;
      if (isValid) {
        const context = exactmatch[0]._source;
        const cleanContext = cleanData(context);
        storeSiteData(cleanContext);
        let genAiDescTextQuery = await _genIAService.GetGenIADescription(bbDescriptionLimit, "", cleanContext);
        let genAiDescText = genAiDescTextQuery.data.result;
        let enableRocketBtn = genAiDescTextQuery.data.result !== "There is not enough information about this site";
        genAiDescObj.description = genAiDescText;
        genAiDescObj.enableRocketBtn = enableRocketBtn;
        return genAiDescObj;
      }
    }
    return genAiDescObj;
}


export default {
    GetGenAiDescription : GetGenAiDescription,
    showRocketBtn : showRocketBtn,
    enableRocketBtn : enableRocketBtn 
};