import "../TCForm.css";
import { BsPlusSquare } from "react-icons/bs";
import TopicCardPills from "./TopicCardPills";
import TopicCardTooltips from "./TopicCardTooltips";
import { useState, useEffect, useContext } from "react";
import ModalCustom from "../../../Modal/ModalCustom";
import { ThemeContext } from "../../../../ThemeContext";
import TopicCardValidations from "../../../../validationClasses/TopicCardValidations";
import { Tooltip } from "bootstrap";
import Synonyms from "./Synonyms/Synonyms";
import { copyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";
import services from "../../../../store/services";

const KeywordInput = ({
  keywordInput,
  selectedKeywords,
  setKeywordInput,
  updateFormField,
  selectedRefinerPage,
  selectedLocation,
  inputState,
  setInputState,
  keywordsForReview,
  disableField,
  revalidateForm,
  tcrequest,
  requestStatus,
  changeClipboardPillType,
  tooltipFor
}) => {
  const keyword = {
    alt: "Keywords Info. 1. Avoid generic terms that can be associated with various site content within Accenture, such as Accenture, skills, process, or analysis. 2. Avoid single keywords, unless it is highly specific, such as an acronym 3. Avoid keywords that have more than three words as it is very unlikely to be entered as a search term"
  };
  const [openKeywordsModal, setOpenKeywordsModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [keywordsErrorMessage, setKeywordsErrorMessage] = useState("");
  const [validInputClass, setValidInputClass] = useState("");
  const theme = useContext(ThemeContext);
  const urlCheckerService = services.urlCheckerService;

  useEffect(() => {
    if (selectedKeywords.length > 0) {
      setInputState("keyword", true);
      setKeywordsErrorMessage("");
      setValidInputClass("is-valid");
    } else if (
      keywordsForReview.length === 0 &&
      selectedKeywords.length === 0
    ) {
      setInputState("keyword", false);
      setKeywordsErrorMessage("");
      setValidInputClass("");
    } else if (selectedKeywords.length === 0) {
      setInputState("keyword", false);
      setKeywordsErrorMessage(
        "Please enter at least one valid, relevant keyword in order to successfully submit this Topic Card request."
      );
      setValidInputClass("is-invalid");
      errorMsg();
    }
  }, [selectedKeywords, keywordsForReview]);

  useEffect(() => {
    const tooltipClass = theme[0] === "dark" ? "tooltip-dark" : "";
    let tooltipElements = document.querySelectorAll(
      '[data-bs-toggle="plus-icon"]'
    );
    [...tooltipElements].map(
      (ttp) =>
        new Tooltip(ttp, {
          tooltipElements,
          trigger: "hover",
          customClass: tooltipClass
        })
    );
  }, [theme]);

  const onChangeKeyword = (event) => {
    setKeywordInput(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addKeyword(event);
    }
  };
  const addKeyword = async ({ key, type }) => {
    const keyword = keywordInput.trim();

    let regex = urlCheckerService.textRegex;

    if (keyword.match(regex)) {
      setInputState("keyword", false);
      setKeywordsErrorMessage(
        'Please do not include the special characters \\ and "'
      );
      setValidInputClass("is-invalid");
    } else {
      setKeywordsErrorMessage("");
      setValidInputClass("");
    }

    let enteredKeywords = selectedKeywords.concat(keywordsForReview);
    const allowedEvents = key === "Enter" || type === "click";
    if (allowedEvents && keyword !== "") {
      if (selectedRefinerPage.length === 0 || selectedLocation.length === 0) {
        setModalMessage("Please select Location and Refiner Page first.");
        setKeywordsErrorMessage("");
        setValidInputClass("");
        setOpenKeywordsModal(true);
      } else if (enteredKeywords.length < 20) {
        let regex = urlCheckerService.textRegex;

        if (keywordInput.match(regex)) {
          setModalMessage(
            'Please do not include the special characters \\ and "'
          );
          setOpenKeywordsModal(true);
          setKeywordsErrorMessage("");
          setValidInputClass("");
        } else {
          setInputState("keyword", null);
          setKeywordsErrorMessage("");
          setValidInputClass("");
          await validateKeywords(
            keyword,
            selectedLocation,
            selectedRefinerPage
          );
        }
      } else {
        setModalMessage("Only 20 keywords are allowed");
        setOpenKeywordsModal(true);
      }
      setKeywordInput("");
    }
  };

  const validateKeywords = async (
    keywordInput,
    selectedLocation,
    selectedRefinerPage
  ) => {
    const lowerCaseKeywords = selectedKeywords.map((kw) => kw.toLowerCase());
    if (lowerCaseKeywords.indexOf(keywordInput.toLowerCase()) !== -1) {
      setModalMessage("You have already added this keyword");
      setOpenKeywordsModal(true);
    } else {
      let keywords = {
        keyword: keywordInput,
        location: selectedLocation,
        refinerPage: selectedRefinerPage,
        tcrequestid: null,
        abortController: null,
        service: services.topicCardService
      };
      let isInputValid = await TopicCardValidations.validateKeywords(keywords);
      if (isInputValid.isValid) {
        let keywordsArray = [...selectedKeywords];
        keywordsArray = [...keywordsArray, keywordInput];
        if (keywordsArray.length > 0) {
          updateFormField("keyword", keywordsArray.sort());
          setInputState("keyword", isInputValid.isValid);
          setValidInputClass("is-valid");
        }
      } else {
        let keywordReview = [...keywordsForReview];
        keywordReview = [...keywordReview, keywordInput];
        updateFormField("keywordsForReview", keywordReview.sort());
        setKeywordsErrorMessage(isInputValid.errorMessage);
        if (selectedKeywords.length === 0) {
          setInputState("keyword", isInputValid.isValid);
          setValidInputClass("is-invalid");
          errorMsg();
        }
      }
    }
  };

  const onClickKeywordsModal = (e) => {
    if (e.type === "mousedown" || e.code === "Enter") {
      setOpenKeywordsModal(false);
    }
  };

  const errorMsg = () => {
    if (inputState.keyword || inputState.keyword === null) {
      return <></>;
    } else {
      return (
        <div id="validate-input" className="invalid">
          {keywordsErrorMessage}
        </div>
      );
    }
  };

  const copyToClipboardHandler = (event) => {
    changeClipboardPillType("Keyword");
    copyToClipboard(event);
  };

  return (
    <>
      {openKeywordsModal ? (
        <ModalCustom
          darkMode={theme === "dark"}
          onClose={onClickKeywordsModal}
          modal_msg={modalMessage}
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize=""
          noHeader={true}
        />
      ) : (
        <div></div>
      )}
      <div className="mb-3 target">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={keyword.alt}
          tooltipText='Keyword recommendations:&nbsp;
          &nbsp;&bull;&nbsp;&nbsp;At least 1 related keyword based on your site content is required; Maximum of 20 keywords can be entered.
          &nbsp;&bull;&nbsp;&nbsp;Avoid generic terms that can be associated with various site content within Accenture, such as "Accenture", "skills", "process", or "analysis"
          &nbsp;&bull;&nbsp;&nbsp;Avoid single keywords unless it is highly specific, such as an acronym
          &nbsp;&bull;&nbsp;&nbsp;Avoid keywords that have more than three words as it is very unlikely to be entered as a search term'
        />
        <label data-testid="topic-card-label" className="label-text required">
          Keywords
        </label>
        <input
          id="keyword-input"
          type="text"
          data-testid="tc-input"
          className={`form-control ${validInputClass}`}
          placeholder="Enter at least 1 related keyword (maximum of 20 )"
          aria-label="Keyword input. Enter at least 1 related keyword (maximum of 20) and press Enter."
          value={keywordInput}
          onChange={(event) => onChangeKeyword(event)}
          onKeyDown={handleKeyDown}
          disabled={disableField}
        />
        {!disableField && (
          <div className="kw-plus-icon">
            <BsPlusSquare
              tabIndex={0}
              role="tooltip"
              data-bs-toggle="plus-icon"
              data-testid={"plus-icon-keyword"}
              data-bs-placement="top"
              data-bs-title={"Add Keyword(s)"}
              className="plus_icon_kw"
              onClick={(event) => addKeyword(event)}
              onKeyDown={handleKeyDown}
            />
          </div>
        )}
        {errorMsg()}
        <TopicCardPills
          selectedTargets={selectedKeywords}
          setSelectedTarget={updateFormField}
          validate={() => {}}
          arrayForReview={keywordsForReview}
          field="keyword"
          hasReviewArray={true}
          disableField={disableField}
          revalidateForm={revalidateForm}
          onCopyToClipBoard={copyToClipboardHandler}
          requestStatus={requestStatus}
        />
        {!disableField ? (
          <Synonyms
            keywords={selectedKeywords}
            keywordsForReview={keywordsForReview}
            setKeywords={updateFormField}
            setModalMessage={setModalMessage}
            setOpenKeywordsModal={setOpenKeywordsModal}
            disableField={disableField}
            selectedRefinerPage={selectedRefinerPage}
            selectedLocation={selectedLocation}
            revalidateKeywords={validateKeywords}
            setValidInputClass={setValidInputClass}
            setKeywordsErrorMessage={setKeywordsErrorMessage}
            tcrequest={tcrequest}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default KeywordInput;
