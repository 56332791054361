import React, { useEffect, useState } from "react";
import "../GenAIDescription/genAiModal&Button.css";
import FieldLabelAndIcon from "./FieldLabelAndIcon";
import rocketImage1 from "../../../../assets/images/rocket_01.svg";
import rocketImage2 from "../../../../assets/images/rocket_02.svg";
import rocketDisabled from "../../../../assets/images/rocket_disabled.svg";
import LoadingSpinner from "../GenAIDescription/GenAiLoadingSpinner";
import GenAiLoadingSpinner from "../GenAIDescription/GenAiLoadingSpinner";

const UrlField = ({
  inputType,
  labelText,
  reqType,
  placeholderText,
  ariaLabelText,
  tooltipText,
  isRequired,
  isDisabledProp,
  disableGenIaBtn,
  urlCheckerService,
  validateField,
  updateFormState,
  checkToloadFromUrl,
  showGenAiSpinner,
  openGenAiModal,
  showAiWarning,
  hideGenAiFeature
}) => {
  const inputID = `${reqType}-${inputType}-input-field`;
  const errorMsgBoxID = `${reqType}-${inputType}-error-msg-box`;
  const genAiMsgID = `genai-warning-msg-box`;

  const rocketImage = false ? rocketImage2 : rocketImage1;

  const rocketImg = () => {
    return disableGenIaBtn ? rocketDisabled : rocketImage;
  };
  const tooltipBody = `<div class="summarize-description" data-testid="summarize-tooltip">
        <p class="tooltip-header"><b>Summarize</b></p>
        <p class="tooltip-info">
            Click this button to automatically generate the Best Bet description
        </p>
    </div>`;
  const genAiWarningMsg =
    "This URL cannot be reached by Gen AI. You may use the form traditionally.";
  const [timer, setTimer] = useState(null);

  // methods
  const updateUIElements = (_validationResult, _userInput) => {
    let domField = null;
    let errorMessageDiv = null;

    domField = document.getElementById(inputID);
    if (_validationResult.isValid) {
      try {
        errorMessageDiv = document.getElementById(errorMsgBoxID);
        errorMessageDiv.textContent = "";
        errorMessageDiv.hidden = true;
        domField.classList.remove("is-invalid");
      } catch (error) {
        console.log("error 1 on url field.");
      }

      domField.classList.add("is-valid");
    } else if (!_validationResult.isValid) {
      try {
        domField.classList.remove("is-valid");
      } catch (error) {
        console.log("error 2 on url field.");
      }

      if (_userInput.length !== 0) {
        domField.classList.add("is-invalid");
        //show error msg if any
        try {
          if (_validationResult.errorMsg.length > 0) {
            errorMessageDiv = document.getElementById(errorMsgBoxID);
            errorMessageDiv.textContent = _validationResult.errorMsg;
            errorMessageDiv.hidden = false;
          }
        } catch (error) {
          console.log("error 3 on url field.");
        }
      } else if (_userInput.length === 0) {
        try {
          domField.classList.remove("is-invalid");
          if (_validationResult.errorMsg.length > 0) {
            errorMessageDiv = document.getElementById(errorMsgBoxID);
            errorMessageDiv.textContent = "";
            errorMessageDiv.hidden = true;
          }
        } catch (error) {
          console.log("error 4 on url field.");
        }
      }
    }
  };

  const processUserInput = async (_userInput) => {
    try {
      let validationResult = await validateField(_userInput, urlCheckerService);
      updateUIElements(validationResult, _userInput);
      updateFormState(inputType, _userInput, validationResult);
    } catch (error) {
      console.log("error processUserInput url field.", error);
    }
  };

  const getFieldValue = (event) => {
    const userInput = event.target.value;
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      processUserInput(userInput);
    }, 900);

    setTimer(newTimer);
  };

  const genAIRocketBtn = (
    <>
      {(showGenAiSpinner && !hideGenAiFeature) && <GenAiLoadingSpinner />}
      <button
        className="gen-ai-rocket-button gen-ai-rocket-button-selector"
        onClick={(e) => {
          openGenAiModal(e, disableGenIaBtn);
        }}
        data-testid="gen-ai-rocket-button"
        id="gen-ai-rocket-button"
        aria-label="Generate Best Bet Description"
        aria-describedby="gen-ai-rocket-tooltip"
        disabled={disableGenIaBtn}
      >
        <img
          src={rocketImg()}
          alt="Rocket"
          role="tooltip"
          data-bs-html="true"
          data-bs-toggle="gen-ai-rocket-tooltip"
          data-bs-placement="right"
          data-bs-title={tooltipBody}
          id="rocket-image"
        />
      </button>
    </>
  );

  return (
    <div id={`${reqType}-${inputType}-input-field-div`}>
      <div className="kw-div-elements-holder-selector">
        <FieldLabelAndIcon
          reqType={reqType}
          inputType={inputType}
          inputID={`${inputID}-label`}
          isRequired={isRequired}
          labelText={labelText}
          tooltipText={tooltipText}
        />
        <div style={{ display: "flex" }}>
          <input
            id={inputID}
            type="text"
            autoComplete="off"
            className={`form-control `}
            data-testid={`${reqType}-${inputType}-text-input-field`}
            placeholder={placeholderText}
            aria-label={ariaLabelText}
            onChange={getFieldValue}
            onBlur={checkToloadFromUrl}
            disabled={isDisabledProp}
          />
          {genAIRocketBtn}
        </div>
      </div>
      {hideGenAiFeature ? <></> : 
        <div
          id={genAiMsgID}
          style={{ visibility: showAiWarning ? "visible" : "hidden", color:"var(--light-mode-genaiwarning-text-color)" }}
          className={`invalid-field-div${showAiWarning ? "" : "-hidden"}`}
        >
          {genAiWarningMsg}
        </div>
      }
      <div id={errorMsgBoxID} className="invalid-field-div"></div>
    </div>
  );
};

export default UrlField;
